import * as React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { base } from "../styles/ColorStyles"
import { H3, P, Pmono, PS, WorkLink, H6, A, SpanTitle } from "../styles/Primitives"
// import OtherWork from "./OtherWork"

export default function SelectWork() {
  const data = useStaticQuery(graphql`
    query HomeImages {
      labelbox: file(relativePath: { eq: "home/labelbox-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      labelbox2: file(relativePath: { eq: "home/labelbox-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      atlas: file(relativePath: { eq: "home/atlas-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
       atlas2: file(relativePath: { eq: "home/atlas2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      kayak: file(relativePath: { eq: "home/kayak-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      touch: file(relativePath: { eq: "home/touch-logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
  
    }
  `)

  let LabelBoxImage = getImage(data.labelbox)
  let LabelBox2 = getImage(data.labelbox2)
  let AtlasImage = getImage(data.atlas)
  let Atlas2 = getImage(data.atlas2)
  // let TouchImage = getImage(data.touch)
  let KayakImage = getImage(data.kayak)
 

  return (
    <SectionWrapper id="work">

      <ContentWrapper>
        {/* Labelbox */}
        <ItemWrapper>
          {/* <ImageWrapper> 
                <GatsbyImage
                    image={LabelBoxImage}
                    alt="Labelbox logo in the center of an rectangle"/>
            </ImageWrapper> */}      
          <TextContent>
            <div>
              {/* <Pmono>2022 - Present</Pmono> */}
              <H3>Labelbox</H3>
              <P>As a full-stack designer, I contribute to developing new labeling tools, iterate existing features, assist customers understand labeling performance, & design growth strategies for self-serve customer acquisition & retention.</P>
            </div>
              <LinkTwoGrid>

              <LinkWrapper href="https://alignerr.com" target="_blank">
              <SpanTitle>2024</SpanTitle>
                <LinkFlex>
                  <H6>Alignerr</H6>
                  <PS>A Labelbox platform to source & manage talent for specialized labeling tasks</PS>
                </LinkFlex>
              </LinkWrapper>


              <LinkWrapper href="https://labelbox.com/blog/how-to-set-up-your-labeling-ops-for-success-with-observability-2/" target="_blank">
              <SpanTitle>2023</SpanTitle>
                <LinkFlex>
                  <H6>Performance Dashboard</H6>
                  <PS>Rich data visualization to monitor labeling/labeler efficacy & performance for projects</PS>
                </LinkFlex>
              </LinkWrapper>

               <LinkWrapper href="https://labelbox.com/blog/coming-soon-auto-segment-powered-by-sam/" target="_blank" style={{borderBottom: "none"}}>
              <SpanTitle>2023</SpanTitle>
                <LinkFlex>
                  <H6>AI Assisted Labeling</H6>
                  <PS>Boosting labeler efficiency with Segment Anything(SAM) powered labeling tools</PS>
                </LinkFlex>
              </LinkWrapper>

            </LinkTwoGrid>
          </TextContent>
        </ItemWrapper>

        {/* Atlas AI */}
        <ItemWrapper>   
          <TextContent>
            <div>
              <H3>Atlas AI</H3>
              <P>As the founding designer, I helped create an AI powered geospatial demand intelligence. The platform helped customers across developing nations access hyperlocal socio-demographic indicators that help them pinpoint market opportunities in data-poor regions.</P>
            </div>
              <LinkTwoGrid>

              <LinkWrapper href="/work/aperture-research/">
              <SpanTitle>2020-22</SpanTitle>
                <LinkFlex>
                  <H6>Data Explorer</H6>
                  <PS>Open-source tool for statisticians & economists to explore microscopic geospatial data (2x2 km scope)</PS>
                </LinkFlex>
              </LinkWrapper>


              <LinkWrapper href="/work/demand-intel/" style={{borderBottom: "none"}}>
              <SpanTitle>2022</SpanTitle>
                <LinkFlex>
                  <H6>Demand Intelligence platform</H6>
                  <PS>Smart AI driven insights to make better operational decisions</PS>
                </LinkFlex>
              </LinkWrapper>

            </LinkTwoGrid>
          </TextContent>
        </ItemWrapper>

       {/* Kayak */}
        <ItemWrapper>   
          <TextContent>
            <div>
              <H3>Kayak</H3>
              <P>I worked on features that help millions of users search for the best flights that match their schedule & preferences at the lowest price possible. I collaborated cross-domain with the user research & business teams to define & design solutions</P>
            </div>
              <LinkTwoGrid>



              <LinkWrapper href="/work/kayak" style={{borderBottom: "none"}}>
              <SpanTitle>2019</SpanTitle>
                <LinkFlex>
                  <H6>Case study</H6>
                  <PS>Case study about the advanced flight search filters on Kayak's flight search platform</PS>
                </LinkFlex>
              </LinkWrapper>

            </LinkTwoGrid>
          </TextContent>
        </ItemWrapper>

         {/* Misc */}
        <ItemWrapper >    
          <TextContent>
            <div>
              <H3>Taking ideas beyond the canvas, personal curiosity</H3>
              <P>I've been experimenting with making AI based applications with some fun experiments. Here's a small collection of some highlights.</P>
            </div>
              <LinkTwoGrid>

              <LinkWrapper href="https://atharvapatil.github.io/handposer/" target="_blank">
              <SpanTitle>2023</SpanTitle>
                <LinkFlex>
                  <H6>Point A Sketch</H6>
                  <PS>Recreating the classic Etch A Sketch by pointing a finger in different directions</PS>
                </LinkFlex>
              </LinkWrapper>

              <LinkWrapper href="/work/punchy/" target="_blank">
              <SpanTitle>2019</SpanTitle>
                <LinkFlex>
                  <H6>Punchy</H6>
                  <PS>A tinyML powered personal boxing trainer built using an Arduino</PS>
                </LinkFlex>
              </LinkWrapper>

              <LinkWrapper href="https://medium.com/swlh/dont-touch-your-face-9e59b8641b7b" target="_blank">
              <SpanTitle>2020</SpanTitle>
                <LinkFlex>
                  <H6>Don’t touch your face</H6>
                  <PS>A web app to alert when you touch your face. Built with teachable machine. Featured in a CHI paper!</PS>
                </LinkFlex>
              </LinkWrapper>

              <LinkWrapper href="https://jabberwocky1871.netlify.app/" target="_blank">
              <SpanTitle>2021</SpanTitle>
                <LinkFlex>
                  <H6>Patil's Library</H6>
                  <PS>Data visualization and list of books read between 2013-2023</PS>
                </LinkFlex>
              </LinkWrapper>

               <LinkWrapper href="/play" style={{borderBottom: "none"}}>
              <SpanTitle>More</SpanTitle>
                <LinkFlex>
                  <H6>View more side quests</H6>
                </LinkFlex>
              </LinkWrapper>

            </LinkTwoGrid>
          </TextContent>
        </ItemWrapper>


      </ContentWrapper>

      {/* <OtherWork /> */}

    </SectionWrapper>
  )
}

const ItemWrapper = styled.div`
  padding: 4rem 1.5rem;
  border-bottom: 1px dashed ${base.border};

  @media (max-width: 650px) {
   padding: 3rem 1rem;
  }

    :last-of-type{
    border: none;
  }
`

const SectionWrapper = styled.section`
  margin: 0 auto;
`

const ContentWrapper = styled.div`
  padding-bottom: 2rem;
`

const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 2px;
  /* grid-column: 1 / span 3; */
  /* @media (max-width: 600px) {
   width: 120px;
  } */
`

const ItemThreeColumn = styled.div`
  display: grid;
  /* grid-template-columns: repeat(12, 1fr); */
  grid-template-columns: 1fr;
  gap: 2rem;
  /* padding: 4.5rem 0rem; */
 

   @media (max-width: 900px) {
   grid-template-columns: 1fr;
   gap: 1rem;
  }
`

const TextContent = styled.div`
  /* grid-column: 5 / span 8; */
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 900px) {
   grid-column: 1 / span 1;
  }
`

const LinkTwoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  /* gap: 0.25rem; */

    @media (max-width: 700px) {
   grid-template-columns: 1fr;
  }
`

const TwoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.5rem;
  column-gap: 1rem;

    @media (max-width: 700px) {
   grid-template-columns: 1fr;
  }
`

const LinkWrapper = styled(A)`
  display: flex;  
  /* flex-direction: colurowmn; */
  /* gap: 1rem; */
  padding: 1.25rem 0.5rem;
  border-bottom: 1px dotted ${base.border};
  background: none;

  :hover {
    background: ${base.hoverneutral};
    ${H6}{
      color: ${base.actiondark};
    }
    ${PS} {
      color: ${base.actiondark};
    }
  }

  @media (max-width: 650px) {
   flex-direction: column;
   gap: 0.5rem;
  }

`

const LinkFlex = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 0.25rem; */
  text-decoration: none;
 
`